<template>
  <div class="home">
    <div class="searchNav">
      <el-button
        class="button"
        icon="el-icon-chat-dot-square"
        type="primary"
        style="margin-left: 0"
        :loading="loading"
        @click="sumitMuti"
        >批量提醒</el-button
      >
      <div class="searchParams">
        <div>
          <radioGroup @radioChange="radioChange" @complete="radioComplete" />
        </div>
        <el-input
          class="input"
          v-model="queryParam.keyword"
          placeholder="请输入货单编号/签收人"
          @keydown.enter.native="getDataList"
        ></el-input>
        <el-button class="button" @click="getDataList">查询</el-button>
      </div>
    </div>
    <el-table
      ref="multipleTable"
      :data="tableData"
      style="width: 100%"
      :max-height="height"
      v-loading="loading"
      @selection-change="handleSelectionChange"
    >
      <el-table-column
        type="selection"
        width="50"
        align="center"
        :selectable="isRender"
      >
      </el-table-column>
      <!-- <el-table-column prop="CompanyName" label="所属公司" width="250">
      </el-table-column> -->
      <el-table-column prop="BillId" label="编号" width="120">
      </el-table-column>
      <el-table-column prop="CreateTime" label="日期" width="120">
        <template slot-scope="scope">
          {{
            scope.row.CreateTime ? scope.row.CreateTime.substring(0, 10) : ""
          }}
        </template>
      </el-table-column>
      <el-table-column prop="CustomerName" label="客户"> </el-table-column>
      <el-table-column prop="ContactPersonName" label="收货人">
        <template slot-scope="scope">
          {{ scope.row.ContactPersonName }}
          {{
            scope.row.ContactPersonName && scope.row.CustomerPhone ? "/" : ""
          }}
          {{ scope.row.CustomerPhone }}
        </template>
      </el-table-column>
      <el-table-column prop="FullName" label="签收人">
        <template slot-scope="scope">
          {{ scope.row.FullName }}
          {{ scope.row.FullName && scope.row.ReceiptPhone ? "/" : "" }}
          {{ scope.row.ReceiptPhone }}
        </template>
      </el-table-column>
      <!-- <el-table-column prop="CustomerPhone" label="签收手机号" width="130">
              <template slot-scope="scope">
                {{
                  scope.row.CustomerPhone != "暂无" ? scope.row.CustomerPhone : ""
                }}
              </template>
            </el-table-column> -->
      <el-table-column prop="Status" label="状态" width="100" align="center">
        <template slot-scope="scope">
          <el-tag v-if="scope.row.Status == 0">待签收</el-tag>
          <el-tag v-if="scope.row.Status == 1" type="success">已签收</el-tag>
          <el-tag v-if="scope.row.Status == 2" type="danger">已拒收</el-tag>
          <el-tag v-if="scope.row.Status == 3" type="warning">有差异</el-tag>
          <el-tag v-if="scope.row.Status == 4" type="danger">已作废</el-tag>
        </template>
      </el-table-column>
      <el-table-column label="操作" width="160" align="center">
        <template slot-scope="scope">
          <el-link
            v-if="scope.row.Status != 4"
            type="primary"
            :underline="false"
            class="linkmargin"
            @click="openNewPage(scope.row)"
            >查看
          </el-link>
          <el-link
            v-if="scope.row.Status == 0"
            type="primary"
            class="linkmargin"
            :underline="false"
            @click="submitSingle(scope.row)"
            >催签
          </el-link>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      layout="total,prev, pager, next,sizes"
      :total="pagination.total"
      :current-page="pagination.current"
      :page-size="pagination.pageSize"
      :page-sizes="pagination.pageSizeOptions"
      style="text-align: right; margin-top: 8px; padding: 0"
      @current-change="currentChange"
      @size-change="handleSizeChange"
    >
    </el-pagination>
  </div>
</template>
  
<script>
const downloadFile = (url, downloadName = "") => {
  // console.log(url);
  const link = document.createElement("a");
  fetch(url)
    .then((res) => res.blob())
    .then((blob) => {
      link.href = URL.createObjectURL(blob);
      // console.log(link.href)
      link.download = downloadName;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    });
};
import radioGroup from "@/components/CompanyRadioGroup.vue";
export default {
  name: "Home",
  components: {
    radioGroup,
    // waybillForm
  },
  data() {
    return {
      activeName: "first",
      pagination: {
        current: 1,
        pageSize: 25,
        total: 0,
        pageSizeOptions: [25, 50, 100],
      },
      sorter: {
        field: "Id",
        order: "desc",
      },
      tableData: [],
      queryParam: { SupplieId: "全部", Status: "0" },
      keyword: "",
      loading: false,
      dialogVisible: false,
      templateId: "",
      templateList: [],
      tabList: [{ label: "待签收", name: "first" }],
      ip: "",
      selectRows: [],
    };
  },
  mounted() {},
  computed: {
    height() {
      return document.documentElement.clientHeight * 0.75;
    },
  },
  methods: {
    //批量催签
    sumitMuti() {
      //   console.log(this.$refs.multipleTable);
      if (this.selectRows.length < 1) {
        this.$message.error("请先选择单据！");
        return;
      }
      this.callService();
    },
    // 单条催签
    submitSingle(scope) {
      if (!/^1[3456789]\d{9}$/.test(scope.CustomerPhone)) {
        // this.dialogVisible = true;
        this.openInputDig(scope);
        return;
      }
      this.selectRows = [scope];
      this.callService();
    },
    openInputDig(obj) {
      let that = this;
      this.$prompt("请输入手机号", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        inputPattern: /^1[3456789]\d{9}$/,
        inputErrorMessage: "手机号码格式不正确",
      })
        .then(({ value }) => {
          obj.CustomerPhone = value;
          obj.IsUpdate = true;
          that.selectRows = [obj];
          that.callService();
          // this.$message({
          //     type: 'success',
          //     message: '你的邮箱是: ' + value
          // });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "取消输入",
          });
        });
    },
    //调取服务
    callService() {
      this.$http
        .post("/BO/BillList/BatchReminBill?DomainName=psq", this.selectRows)
        .then((res) => {
          if (res.Success) {
            this.$message.success(res.Data);
          } else {
            this.$message.error(res.Msg);
          }
          this.$refs.multipleTable.clearSelection();
          this.selectRows = [];
        });
    },
    /**
     * 条件判断，添加样式
     * @param row
     * @returns classname
     */
    isRender(row) {
      var phone = row.CustomerPhone;
      if (!/^1[3456789]\d{9}$/.test(phone)) {
        return false;
      } else return true;
    },
    // 多选
    handleSelectionChange(rows) {
      // console.log(rows);
      this.selectRows = rows;
    },
    radioComplete(e) {
      if (e) {
        this.queryParam.SupplieId = e;
        this.getDataList();
      }
    },
    // 打开单据页面
    openNewPage(row) {
      // let isClient = sessionStorage.getItem("client");
      let url = `${location.href.split("#")[0]}#/SendSMS?prop=${row.Id}`;
      //  if (isClient) {
      //   url = `${location.href.split("#")[0]}#/SendSMSCopy?prop=${row.Id}`;
      // }
      window.open(url, "_blank");
    },
    // 公司切换
    radioChange(e) {
      if (e == "more") return;
      this.queryParam.SupplieId = e;
      this.getDataList();
    },
    // 页签切换
    handleClick(tab, event) {
      this.pagination = {
        current: 1,
        pageSize: 25,
        total: 0,
        pageSizeOptions: [25, 50, 100],
      };
      this.queryParam.Status = this.activeName + "";
      if (this.activeName == "first") this.queryParam.Status = "";
      this.getDataList();
    },
    currentChange(page) {
      this.pagination.current = page;
      this.getDataList();
    },
    handleSizeChange(lint) {
      this.pagination.pageSize = lint;
      this.getDataList();
    },
    getDataList() {
      this.loading = true;
      this.$http
        .post("/BO/BillList/GetBillList", {
          PageIndex: this.pagination.current,
          PageRows: this.pagination.pageSize,
          SortField: this.sorter.field || "Id",
          SortType: this.sorter.order,
          Search: this.queryParam,
        })
        .then((resJson) => {
          this.loading = false;
          if (resJson.Success) {
            this.tableData = resJson.Data;
            if (this.tableData) {
              const pagination = { ...this.pagination };
              pagination.total = resJson.Total;
              this.pagination = pagination;
            }
          }
        });
    },
  },
};
</script>
<style lang="less" scoped>
/deep/.el-menu-item.is-active {
    color: #1aad19;
}
.el-button--primary {
    color: #FFF;
    background-color: #1aad19;
    border-color: #1aad19;
}
/deep/ .el-radio-button__orig-radio:checked+.el-radio-button__inner {
    color: #FFF;
    background-color: #1aad19;
    border-color: #1aad19;
}
/deep/.el-radio-button__inner:hover {
    color: #1aad19;
}
/deep/ .el-tabs__nav {
  width: 100%;
  background: #fff;
}

/deep/ .el-tab-pane {
  width: 100%;
  background: #fff;
}

/deep/ .el-tabs__item {
  width: 16.6%;
  text-align: center;
  font-weight: bold;
  padding: 0;
}

/deep/ .el-tabs__item:hover {
  background: #ecf5ff;
}
</style>
  